
@import "../../../../../wwwroot/scss/_variables";

.door-controls-modal-dialog {
  position: relative;
  width: 100%;
  height: calc(100% - 1.75rem * 2);
}

.door-controls-modal-content {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border: none;
  border-radius: 0;
  box-shadow: none;
  min-height: 100vh;
  min-height: 100dvh;
  text-align: left;
  height: 100%;
}

.modal-header {
  background-color: white;
  display: flex;
  justify-content: space-between;
}

.modal-body {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  flex: 1;
}

.modal-item {
  border-bottom: 1px solid $ibox-color;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.modal-item-last {
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.error {
  color: $error-color;
  line-height: 1.5rem;
  font-weight: 400;
}

.modal-footer {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  background-color: whitesmoke;

  a {
    text-decoration: none;
    gap: 0.25rem;
  }

  .sign-out-link {
    color: $danger-color;

    .icon {
      background-color: $danger-color;
    }
  }

  .cancel-cart-link {
    color: $primary-color;

    .icon {
      background-color: $primary-color;
    }
  }
}

h4 {
  margin-top: 5px;
}

@media #{$medium-screen} {
  .door-controls-modal-dialog {
    margin: 20px auto;
    max-width: 46em;
    height: calc(100% - 1.75rem * 2);
  }

  .door-controls-modal-content {
    min-height: auto;
    // same as model.scss
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  }
}
