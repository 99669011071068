
.recent-sales {
  margin-top: -20px;
}

.recent-orders {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.order-card {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.order-details {
  align-self: flex-start;
}

.order-action {
  align-self: flex-end;
  text-align: right;
}

.order-description {
  color: #333;
  font-weight: 500;
  font-size: 14px;
}

.order-date {
  font-size: 14px;
}

.order-action a {
  display: inline-block;
  background-color: #0078e7;
  color: #fff;
  padding: 5px;
  border: none;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  width: 100px;
  font-size: 15px;
  text-decoration: none;
  text-align: center;
  border-radius: 6px;
}

.order-action a:hover {
  background-color: #005cbf;
}

.no-sales {
  padding-left: 5px;
}

