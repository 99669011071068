
@import "../../../../../wwwroot/scss/variables";

.door-seller-profile {
  text-align: left;

  .icon-arrow-right-from-bracket {
    background-color: $primary-color;
  }
}

h4 {
  margin-top: 10px;
  margin-bottom: 10px;
}
