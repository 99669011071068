
@import "../../../../../wwwroot/scss/variables";

  .preference-info {
    font-size: 13px;
    font-weight: bold;
    margin-top: 10px;
  }

  button.pure-button-primary, a.pure-button-primary
  {
    max-width: 200px; 
  }

  .btn-primary[disabled] {
    cursor: not-allowed;
    filter: alpha(opacity=65);
    opacity: .65;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  
