
@import "../../../../../wwwroot/scss/_variables";

.modal-header {
  background-color: white;
}

.door-controls-modal-dialog {
  position: relative;
  width: 100%;
}

.door-controls-modal-content {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border: none;
  border-radius: 0;
  box-shadow: none;
  min-height: 100vh;
  text-align: left;
}

.modal-header {
  display: flex;
  justify-content: space-between;
}

.modal-item {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
}

@media #{$medium-screen} {
  .door-controls-modal-dialog {
    margin: 70px auto;
    max-width: 46em;
  }

  .door-controls-modal-content {
    min-height: auto;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  }
}
