
@import "../../../../wwwroot/scss/variables";

.link {
  display: flex;
  gap: 0.5rem;
  cursor: pointer;
  margin: 0;
  padding: 5px 10px;
  border-radius: 0.5rem;

  &.disconnected {
    animation: error-flash 1s step-start infinite;
  }

  &.connecting {
    background-color: $warning-color;
  }

  &.connected {
    background-color: $success-color;
  }
}

.is-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-buttons {
  .nav-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 28px;
    height: 28px;
    margin-right: 13px;

    &:last-child {
      margin-right: 2px;
    }
  }

  .control-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
  }

  .nav-button-label {
    font-size: 0.8rem;
  }
  
  .door-control-button {
    transition: background-color 0.3s ease;

    svg {
      fill: white;

      &:hover {
        fill: #e1e1e1;
      }
    }
  }
}

.door-control-button {
  background-color: transparent;
  border: none;
  box-shadow: none;
  border-radius: 50%;
  font-weight: 700;
  display: block;
  margin-right: 0;
  position: relative;
  outline: 0;
  width: 32px;
  height: 32px;
}

.control-button-icon {
  display: flex;
  width: 32px;
  justify-content: center;
  align-items: center;
  margin: 0;
  color: white;
}

.avatar {
  background-color: transparent;
  border: none;
  box-shadow: none;
  border-radius: 50%;
  font-weight: 700;
  display: block;
  margin-right: 0;
  position: relative;
  outline: 0;
  margin-bottom: 2px;

  .avatar-circle {
    background-color: #0055cc;
    border-radius: 50%;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .avatar-circle-inner {
    color: white;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    line-height: 1;
    margin-bottom: 1px;
  }
}

.theme-localtix .avatar .avatar-circle {
  background-color: #ff831be6;
}

.icon-circle {
  border-radius: 50%;
  height: 32px;
  width: 32px;

  img {
    width: 100%;
    height: 100%;
  }
}

@keyframes error-flash {
  50% {
    background-color: $danger-color;
  }
}
