
@import "../../../../../wwwroot/scss/variables";
.refresh-button {
  .icon {
    background-color: $primary-color;
  }
}

.terminals-list {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 0.75rem;
}

.terminal {
  background: none;
  text-align: left;
  appearance: none;

  display: flex;
  align-items: center;
  gap: 1rem;
  border-style: solid;
  border-width: 1px;
  border-radius: 0.5rem;
  border-color: $ibox-color;
  padding: 1rem 1rem;

  .icon-mobile-screen {
    background-color: $ibox-color;
    width: 30px;
    height: 30px;
    transform: rotate(180deg);
  }

  .terminal-description {
    font-size: 0.8rem;
    font-weight: 400;
  }

  .terminal-label {
    font-weight: 400;
  }

  .online-status {
    background-color: $danger-color;
    margin-left: 0.375rem;
    height: 10px;
    width: 10px;
  }

  .reset-container {
    width: 40px;
  }

  .reset-button {
    color: $danger-color;
    font-size: 0.925rem;
    padding: 0;
    position: relative;
    top: 2px;

    .icon {
      background-color: $danger-color;
      height: 16px;
      width: 16px;
      margin-left: 6px;
    }
  }

  &.selected {
    border-color: transparent;
    box-shadow: 0 0 0 3px $primary-color;

    .icon-mobile-screen {
      background-color: $primary-color;
    }
  }

  &.online {
    .online-status {
      background-color: $success-color;
    }
  }
}

.warning-container {
  background: $danger-color;
  text-align: center;
  color: white;
  font-weight: 400;
  padding: 0.5rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

.error {
  color: $error-color;
  line-height: 1.5rem;
  font-weight: 400;
}

@media #{$medium-screen} {
  .terminals-list {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
