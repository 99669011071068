﻿// colors
$primary-color: var(--primary-color);
$primary-text-color: var(--primary-text-color);
$secondary-text-color: var(--secondary-text-color);
$success-color: var(--success-color);
$info-color: var(--info-color);
$warning-color: var(--warning-color);
$danger-color: var(--danger-color);
$error-color: var(--error-color);
$modal-error-color: var(--modal-error-color);
$header-color: var(--header-color);
$footer-color: var(--footer-color);
$preview-color: var(--preview-color);
$social-share-color: var(--social-share-color);
$ibox-color: var(--ibox-color);

$medium-screen: 'screen and (min-width: 48em)';
$touch-screen: '(hover: none) and (pointer: coarse) and (max-width: 64em)';

:root {
  --primary-color: #0077c8;
  --primary-text-color: #000;
  --secondary-text-color: #0077c8;
  --success-color: #28a745;
  --info-color: #36bba5;
  --warning-color: #f8ac59;
  --danger-color: #ed5565;
  --error-color: #ff0000;
  --modal-error-color: #9e323e;
  --header-color: rgba(0, 0, 0, 0.8);
  --footer-color: rgba(0, 0, 0, 0.9);
  --preview-color: #004d77;
  --social-share-color: #4c4c4c;
  --ibox-color: #c4c4c4;
  
  .theme-localtix {
    --primary-color: #ff8632;
    --secondary-text-color: #ff8632;
  }
}

